import { message } from "antd";
import moment from "moment";


export function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}
  
export function beforeUploadImage(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
	if (!isJpgOrPng) {
		message.error('Solo se aceptar los formatos JPG/PNG/PDF!');
		return false;
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('La imagen debería ser menor a 2MB!');
		return false;
	}
	return isJpgOrPng && isLt2M;
}

export function beforeUploadOnlyImage(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('Solo se aceptar los formatos JPG/PNG!');
		return false;
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('La imagen debería ser menor a 2MB!');
		return false;
	}
	return isJpgOrPng && isLt2M;
}

export function beforeCurrentDate(current){
	return moment() <= current;
}

export function afterCurrentDate(current){
	return moment().subtract("1", 'day') >= current;
}