/* eslint-disable react/prop-types */
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { isMobile } from "react-device-detect";
import { userSignOut } from "../../appRedux/actions/Auth";

const SubMenu = Menu.SubMenu;

const getNavStyleSubMenuClass = (navStyle) => {
	if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
		return "gx-no-header-submenu-popup";
	}
	return "";
};

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

	let {navStyle, themeType} = useSelector(({settings}) => settings);
	let {pathname} = useSelector(({common}) => common);
	const dispatch = useDispatch();


	const menu = useSelector(({auth}) => auth.menu);


	const getNoHeaderClass = (navStyle) => {
		if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
			return "gx-no-header-notifications";
		}
		return "";
	};

	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split("/")[1];

	function renderMenu(data){
		return data.map((item)=>{
			if(item.children.length > 0){
				return <SubMenu key={item.id} popupClassName={getNavStyleSubMenuClass(navStyle)}
					title={<span> <i className={item.icon}/>
						<span>{item.title}</span></span>}>
					{ renderMenu(item.children) }
				</SubMenu>;
			}
			return <Menu.Item key={item.id}>
				<Link to={item.path}><i className={item.icon}/>
					<span>{item.title}</span></Link>
			</Menu.Item>;
		});
	}

	return (<>

		<SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
		<div className="gx-sidebar-content">
			<br/>
			<div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
				<UserProfile/>
				{/* <AppsNavigation/> */}
			</div>
			<CustomScrollbars className="gx-layout-sider-scrollbar">
				<Menu
					defaultOpenKeys={[defaultOpenKeys]}
					selectedKeys={[selectedKeys]}
					theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
					mode="inline">

					{menu ? renderMenu(menu): null}

					{isMobile ? <Menu.Item key="salirMobile">
						<a onClick={() => dispatch(userSignOut())}><i className={"icon icon-close-circle"}/>
							<span>Cerrar sesión</span></a>
					</Menu.Item> : null}

				</Menu>
			</CustomScrollbars>
		</div>
	</>
	);
};

SidebarContent.propTypes = {};

export default SidebarContent;

