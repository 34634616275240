import {INIT_URL, USERS_DATA, USERS_SET, USER_ACTION, USER_SET } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	users: null,
	action: [[],[]],
	user: null,
	data: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case USERS_SET: {
		return {
			...state,
			users: action.payload,
		};
	}

	case USER_ACTION: {
		return {
			...state,
			action: action.payload,
		};
	}

	case USER_SET: {
		return {
			...state,
			user: action.payload,
		};
	}

	case USERS_DATA: {
		return {
			...state,
			data: action.payload,
		};
	}

	default:
		return state;
	}
};
