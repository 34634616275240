import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	TRAINING_LEVEL_SELECT_SET
} from "../../constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
  
export const getTrainingLevelsSelect = () => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
      
		axios.get("training_levels/select").then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS});
 
				dispatch({type: TRAINING_LEVEL_SELECT_SET, payload: data.levels});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};