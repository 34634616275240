import {INIT_URL, FETCH_START_REPORT_USERS, FETCH_SUCCESS_REPORT_USERS, FETCH_ERROR_REPORT_USERS, SET_ACTION_REPORT_USERS, SET_REPORT_USERS} from "constants/ActionTypes";
import { FETCH_ERROR_REPORT_ATTEMPTS, FETCH_ERROR_REPORT_COBROS, FETCH_ERROR_REPORT_PAGOS, FETCH_START_REPORT_ATTEMPTS, FETCH_START_REPORT_COBROS, FETCH_START_REPORT_PAGOS, FETCH_SUCCESS_REPORT_ATTEMPTS, FETCH_SUCCESS_REPORT_COBROS, FETCH_SUCCESS_REPORT_PAGOS, SET_ACTION_REPORT_ATTEMPTS, SET_ACTION_REPORT_COBROS, SET_ACTION_REPORT_PAGOS, SET_REPORT_ATTEMPTS, SET_REPORT_COBROS, SET_REPORT_PAGOS } from "../../../constants/ActionTypes";
import { FETCH_START_REPORT_ASESORS, FETCH_SUCCESS_REPORT_ASESORS, FETCH_ERROR_REPORT_ASESORS, SET_REPORT_ASESORS, SET_ACTION_REPORT_ASESORS } from "../../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	users: null,
	error_users: "",
	loading_users: false,
	message_users: "",
	action_users: "",
	cobros: null,
	pagos: null,
	asesors: null,
	attempts: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case FETCH_START_REPORT_USERS: {
		return {...state, error_users: "", message_users: "", loading_users: true};
	}

	case FETCH_SUCCESS_REPORT_USERS: {
		return {...state, error_users: "", message_users: "", loading_users: false};
	}

	case FETCH_ERROR_REPORT_USERS: {
		return {...state, loading_users: false, error_users: action.payload, message_users: ""};
	}

	case SET_ACTION_REPORT_USERS: {
		return {
			...state,
			action_users: action.payload,
		};
	}

	case SET_REPORT_USERS: {
		return {
			...state,
			users: action.payload,
		};
	}

	case FETCH_START_REPORT_COBROS: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: true};
	}

	case FETCH_SUCCESS_REPORT_COBROS: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: false};
	}

	case FETCH_ERROR_REPORT_COBROS: {
		return {...state, loading_cobros: false, error_cobros: action.payload, message_cobros: ""};
	}

	case SET_ACTION_REPORT_COBROS: {
		return {
			...state,
			action_cobros: action.payload,
		};
	}

	case SET_REPORT_COBROS: {
		return {
			...state,
			cobros: action.payload,
		};
	}

	case FETCH_START_REPORT_PAGOS: {
		return {...state, error_pagos: "", message_pagos: "", loading_pagos: true};
	}

	case FETCH_SUCCESS_REPORT_PAGOS: {
		return {...state, error_pagos: "", message_pagos: "", loading_pagos: false};
	}

	case FETCH_ERROR_REPORT_PAGOS: {
		return {...state, loading_pagos: false, error_pagos: action.payload, message_pagos: ""};
	}

	case SET_ACTION_REPORT_PAGOS: {
		return {
			...state,
			action_pagos: action.payload,
		};
	}

	case SET_REPORT_PAGOS: {
		return {
			...state,
			pagos: action.payload,
		};
	}

	case FETCH_START_REPORT_ASESORS: {
		return {...state, error_pagos: "", message_pagos: "", loading_pagos: true};
	}

	case FETCH_SUCCESS_REPORT_ASESORS: {
		return {...state, error_pagos: "", message_pagos: "", loading_pagos: false};
	}

	case FETCH_ERROR_REPORT_ASESORS: {
		return {...state, loading_pagos: false, error_pagos: action.payload, message_pagos: ""};
	}

	case SET_ACTION_REPORT_ASESORS: {
		return {
			...state,
			asesors: action.payload,
		};
	}

	case SET_REPORT_ASESORS: {
		return {
			...state,
			asesors: action.payload,
		};
	}
	
	case FETCH_START_REPORT_ATTEMPTS: {
		return {...state, error_attempts: "", message_attempts: "", loading_attempts: true};
	}

	case FETCH_SUCCESS_REPORT_ATTEMPTS: {
		return {...state, error_attempts: "", message_attempts: "", loading_attempts: false};
	}

	case FETCH_ERROR_REPORT_ATTEMPTS: {
		return {...state, loading_attempts: false, error_attempts: action.payload, message_attempts: ""};
	}

	case SET_ACTION_REPORT_ATTEMPTS: {
		return {
			...state,
			action_attempts: action.payload,
		};
	}

	case SET_REPORT_ATTEMPTS: {
		return {
			...state,
			attempts: action.payload,
		};
	}

	default:
		return state;
	}
};
