import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Roles from "./Roles";
import {connectRouter} from "connected-react-router";
import Users from "./Users";
import Cities from "./Cities";
import Modal from "./Modal";
import Genders from "./Genders";
import EducationLevels from "./EducationLevels";
import TrainingLevels from  "./TrainingLevels";
import Specialties from  "./Specialties";
import BusinessTypes from  "./BusinessTypes";
import Business from "./Business";
import Curriculum from "./Curriculums";
import Schedules from "./Schedules";
import Plans from "./Plans";
import Variables from "./Variables";
import Vacations from "./Vacations";
import Appointments from "./Appointments";
import Account from "./Account";
import Prescriptions from "./Prescriptions";
import Medicines from "./Medicines";
import Histories from "./Histories";
import Cobros from "./Cobros";
import Refers from "./Refers";
import PaymentRefers from "./PaymentRefers";
import Charges from "./Charges";
import Dashboard from "./Dashboard";
import ChargesDoctor from "./ChargesDoctor";
import Orders from "./Orders";
import Invoices from "./Invoices";
import AdminReports from "./Reports/Admin";
import DoctorReports from "./Reports/Doctors";
import ClinicReports from "./Reports/Clinicas";

export default (history) => combineReducers({
	router: connectRouter(history),
	settings: Settings,
	auth: Auth,
	common: Common,
	roles: Roles,
	users: Users,
	cities: Cities,
	modal: Modal,
	genders: Genders,
	educationLevels: EducationLevels,
	trainingLevels: TrainingLevels,
	specialties: Specialties,
	businessTypes: BusinessTypes,
	business: Business,
	curriculum: Curriculum,
	schedules: Schedules,
	plans: Plans,
	variables: Variables,
	vacations: Vacations,
	appointments: Appointments,
	account: Account,
	prescriptions: Prescriptions,
	medicines: Medicines,
	histories: Histories,
	cobros: Cobros,
	refers: Refers,
	payment_refers: PaymentRefers,
	charges: Charges,
	dashboard: Dashboard,
	charges_doctor: ChargesDoctor,
	orders: Orders,
	invoices: Invoices,
	admin_reports: AdminReports,
	doctor_reports: DoctorReports,
	clinica_reports: ClinicReports
});
