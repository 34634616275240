import {
	FETCH_ERROR_BUSINESS,
	FETCH_START_BUSINESS,
	FETCH_SUCCESS_BUSINESS,
	INIT_URL,
	BUSINESSES_SET,
	BUSINESSES_EMPLOYES_SET,
	BUSINESS_ACTION,
	BUSINESS_RESET_ACTION
} from "../../constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};

export const initBusiness = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
		axios.post("business/after", datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_BUSINESS});
				dispatch({type: BUSINESS_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const getBusinesses = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
      
		axios.get("businesses?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_BUSINESS});
 
				dispatch({type: BUSINESSES_SET, payload: data.businesses});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const setAction = (action) => {
	return (dispatch) => {
		dispatch({type: BUSINESS_ACTION, payload: action});
	};
};
 
export const resetAction = () => {
	return (dispatch) => {
		dispatch({type: BUSINESS_RESET_ACTION, payload: null});
	};
};

export const updateBusiness = (datos, action) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
		dispatch({type: BUSINESS_ACTION, payload: "update_business"}); 
		axios.put("businesses", datos).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_BUSINESS});
				dispatch({type: BUSINESS_ACTION, payload: action}); 
				//dispatch({type: BUSINESSES_SET, payload: data.business});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const updateStatus = (datos, action) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});   
		dispatch({type: BUSINESS_ACTION, payload: "update_status"});   
		axios.put("businesses/status", datos).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_BUSINESS}); 
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data});

				dispatch({type: BUSINESS_ACTION, payload: action});  
				//dispatch({type: FETCH_OK});

			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const getEmployes = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
      
		axios.get("businesses/employes?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_BUSINESS});
 
				dispatch({type: BUSINESSES_EMPLOYES_SET, payload: data.employes});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const getEmployesSchedule = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
      
		axios.get("businesses/employes/schedules?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_BUSINESS});
 
				dispatch({type: BUSINESSES_EMPLOYES_SET, payload: data.employes});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const sendLink = (datos) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
		dispatch({type: BUSINESS_ACTION, payload: "employe_send"});      
		axios.put("businesses/sendLink", datos).then(({data}) => {

			if (data.result) { 
				dispatch({type: FETCH_SUCCESS_BUSINESS});
				dispatch({type: BUSINESS_ACTION, payload: "employe_sended"});
				//dispatch({type: BUSINESSES_EMPLOYES_SET, payload: data.employes});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.message});
				dispatch({type: BUSINESS_ACTION, payload: "employe_send_error"});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const sendValores = (datos) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
		dispatch({type: BUSINESS_ACTION, payload: "employe_valores"});      
		axios.put("employes", datos).then(({data}) => {

			if (data.result) { 
				dispatch({type: FETCH_SUCCESS_BUSINESS});
				dispatch({type: BUSINESS_ACTION, payload: "employe_valores_update"});
				//dispatch({type: BUSINESSES_EMPLOYES_SET, payload: data.employes});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.message});
				dispatch({type: BUSINESS_ACTION, payload: "employe_send_error"});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const reemplacedEmploye = (datos, action) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
		dispatch({type: BUSINESS_ACTION, payload: "employe_reemplazed"});      
		axios.put("businesses/reemplazar", datos).then(({data}) => {

			if (data.result) { 
				dispatch({type: FETCH_SUCCESS_BUSINESS});
				dispatch({type: BUSINESS_ACTION, payload: action});
				//dispatch({type: BUSINESSES_EMPLOYES_SET, payload: data.employes});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.message});
				dispatch({type: BUSINESS_ACTION, payload: "employe_send_error"});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};

export const getAllEmployes = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_BUSINESS});
      
		axios.get("businesses/all/employes?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_BUSINESS});
 
				dispatch({type: BUSINESSES_EMPLOYES_SET, payload: data.employes});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_BUSINESS, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_BUSINESS, payload: error});
		});
	};
};