import React, {useState} from "react";
import {Layout, Typography} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";


import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import UserProfileMobileMobile from "../Sidebar/UserProfileMobile";
import { isMobile } from "react-device-detect";

const {Header} = Layout;
const {Title} = Typography;

const Topbar = () => {
  const variables_global = useSelector(({variables}) => variables.variables_global)
  const user = useSelector(({auth}) => auth.authUser);
  //const token = JSON.parse(localStorage.getItem('user'));
  const {locale, navStyle} = useSelector(({settings}) => settings);
  const { navCollapsed, width} = useSelector(({common}) => common);
  const {searchText, setSearchText} = useState('');
  const dispatch = useDispatch();


  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    //setSearchText(evt.target.value);
  };
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      {variables_global ? user.rl.includes(variables_global.id_rol_negocio) ? <Title level={4}>{user.doidCs[0].name}</Title > : null : null}
      {variables_global ? user.rl.includes(variables_global.id_rol_admin_negocio) ?  <Title level={4} >{user.doidAcs[0].name}</Title > : null : null}
      {isMobile ? <UserProfileMobileMobile /> : null} 

    </Header>
  );
};

export default Topbar;
