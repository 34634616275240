import {FETCH_ERROR_ORDER, FETCH_START_ORDER, FETCH_SUCCESS_ORDER, INIT_URL, SET_ACTION_ORDER, SET_CURRENT_ORDER  } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	options: null,
	//action: [[],[]],
	current_order: null,
	loading: false,
	error: "",
	action: ""
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case FETCH_START_ORDER: {
		return {...state, error: "", message: "", loading: true};
	}
	case FETCH_SUCCESS_ORDER: {
		return {...state, error: "", message: "", loading: false};
	}
	case FETCH_ERROR_ORDER: {
		return {...state, loading: false, error: action.payload, message: ""};
	}
	case SET_ACTION_ORDER: {
		return {
			...state,
			action: action.payload,
		};
	}
	case SET_CURRENT_ORDER: {
		return {
			...state,
			current_order: action.payload,
		};
	}
	default:
		return state;
	}
};
