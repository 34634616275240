import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	BUSINESS_TYPES_SELECT_SET,
	BUSINESS_TYPES_ACTION
} from "../../constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
  
export const getBusinessTypesSelect = () => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.get("business_types/select").then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: BUSINESS_TYPES_SELECT_SET, payload: data.business_types});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const setAction = (action) => {
	return (dispatch) => {
		dispatch({type: BUSINESS_TYPES_ACTION, payload: action});
	};
};