import {FETCH_START_PAYMENT_REFERS, SET_PAYMENT_REFERS, FETCH_SUCCESS_PAYMENT_REFERS, SET_ACTION_PAYMENT_REFERS, FETCH_ERROR_PAYMENT_REFERS} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	payment_refers: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_PAYMENT_REFERS: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_PAYMENT_REFERS: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_PAYMENT_REFERS: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_PAYMENT_REFERS: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_PAYMENT_REFERS: {
		return {...state, payment_refers: action.payload};
	}
	default:
		return state;
	}
};
