import {SET_VACATIONS} from "constants/ActionTypes";
import {FETCH_START_VACATIONS, FETCH_SUCCESS_VACATIONS, SET_ACTION_VACATIONS, FETCH_ERROR_VACATIONS, RESET_ACTION_VACATIONS} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	vacations: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_VACATIONS: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_VACATIONS: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_VACATIONS: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_VACATIONS: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_VACATIONS: {
		return {...state, vacations: action.payload};
	}
	case RESET_ACTION_VACATIONS: {
		return {...state, loading: false, error: "", message: "", response: false, action: ""};
	}
	default:
		return state;
	}
};
