/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, {useEffect} from "react";
import { Affix, Button, Tabs } from "antd";

import { useSelector} from "react-redux";


import TabDoctor from "components/Register/TabDoctor";
import TabClinic from "../components/Register/TabClinic";
import { TeamOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";


const { TabPane } = Tabs;

function callback() {

}

const SignUp = (props) => {

	const token = useSelector(({auth}) => auth.token);
	const { code } = useParams();


	useEffect(() => {
		if (token !== null) {
			props.history.push("/");
		}
	});

	return (
		<>
			<Affix offsetTop={20} >
				{/* eslint-disable-next-line no-undef */}
				<Button href={`${process.env.REACT_APP_PACIENT_FRONT}`} style={{float: "right", marginRight: "20px", backgroundColor: "cyan"}} icon={<TeamOutlined />}>
          Área de Pacientes
				</Button>
			</Affix>
			<div className="gx-app-login-wrap-si">
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-logo-content-si">
							<div className="gx-app-logo-content-si-bg">
								<img src={require("assets/images/doctors.png")} alt='Neature'/>
							</div>
							<div className="gx-app-logo-wid">
								{/* <h1><IntlMessages id="app.userAuth.signUp"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
							</div>
							<div className="gx-app-logo">
								<img alt="example" src={require("assets/images/logo.png")}/>
							</div>
						</div>

						<div className="gx-app-login-content">
							<Tabs defaultActiveKey="1" onChange={callback} >
              
								<TabPane tab="Soy Médico" key="1">
									<TabDoctor asesor={code} />
								</TabPane>
								<TabPane tab="Clínica/Hospital" key="2">
									<TabClinic />
								</TabPane>
								{/* <TabPane tab="Comisionista" key="3">
                <TabUser />
              </TabPane> */}
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignUp;
