import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	SPECIALTIES_SELECT_SET,
	ESPECIALTY_ACTION,
	FETCH_START_MODAL,
	FETCH_SUCCESS_MODAL,
	FETCH_OK_MODAL,
	FETCH_ERROR_MODAL,
	FETCH_ACTION_OK,
	SPECIALTY_SET
} from "../../constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
  
export const getSpecialtiesSelect = () => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
      
		axios.get("specialties/select").then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS});
 
				dispatch({type: SPECIALTIES_SELECT_SET, payload: data.specialties});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const getSpecialties = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
      
		axios.get("specialties?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS});
 
				dispatch({type: SPECIALTIES_SELECT_SET, payload: data.specialties});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const getAction = (action) => {
	return (dispatch) => {
		dispatch({type: ESPECIALTY_ACTION, payload: action});
	};
};

export const resetActions = () => {
	return (dispatch) => {
		dispatch({type: ESPECIALTY_ACTION, payload: [[],[]]});
	};
};

export const addSpeciality = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.post("specialties", datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const updateSpeciality = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.put("specialties", datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const removeSpeciality = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.delete("specialties/"+datos).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const getSpeciality = (id, action) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.get("specialties/detail/"+id).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: SPECIALTY_SET, payload: data.specialty});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};