// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const FETCH_OK = "fetch_ok";
export const FETCH_ACTION = "FETCH_ACTION"; 
export const RESET_ACTION_COMMON = "RESET_ACTION_COMMON";
export const RESET_OK = "reset_ok";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

export const RESET_AUTH = "RESET_AUTH";

//Generic Modal
export const FETCH_START_MODAL = "fetch_start_modal";
export const FETCH_SUCCESS_MODAL = "fetch_success_modal";
export const FETCH_ERROR_MODAL = "fetch_error_modal";
export const FETCH_OK_MODAL = "fetch_ok_modal";
export const FETCH_ACTION_OK = "fetch_action_modal";
export const RESET_ACTION_MODAL = "RESET_ACTION_MODAL";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_MENU_SET = "user_menu_set";
export const USER_PERMISSIONS_SET = "user_permissions_set";
export const USER_ACTIONS_SET = "user_actions_set";

//Roles const
export const ROLES_SET = "roles_set";
export const UPDATE_PERMISSIONS = "update_permissions";

//Users
export const USERS_SET = "users_set";
export const USER_ACTION = "user_action";
export const USER_SET = "user_set";
export const SET_CURRENT_KEY = "set_current_key";
export const USERS_DATA = "USERS_DATA";
//Cities
export const CITIES_SET = "cities_set";

//GENDERS
export const GENDERS_SET = "GENDERS_SET";

//EDUCATIONS LEVELS
export const EDUCATIONS_LEVEL_SELECT_SET = "EDUCATIONS_LEVEL_SELECT_SET";

//TRAINING LEVELS
export const TRAINING_LEVEL_SELECT_SET = "TRAINING_LEVEL_SELECT_SET";

//ESPECIALTIES
export const SPECIALTIES_SELECT_SET = "SPECIALTIES_SELECT_SET";
export const ESPECIALTY_ACTION = "ESPECIALTY_ACTION";
export const SPECIALTY_SET = "SPECIALTY_SET";
//BUSINESS TYPES
export const BUSINESS_TYPES_SELECT_SET = "BUSINESS_TYPES_SELECT_SET";
export const BUSINESS_TYPES_ACTION = "BUSINESS_TYPES_ACTION";
export const BUSINESS_SET = "BUSINESS_SET";
export const BUSINESSES_EMPLOYES_SET = "BUSINESS_EMPLOYES_SET";
export const BUSINESSES_SET = "BUSINESSES_SET";
export const BUSINESS_ACTION = "BUSINESS_ACTION";
export const BUSINESS_RESET_ACTION = "BUSINESS_RESET_ACTION";
export const FETCH_START_BUSINESS = "FETCH_START_BUSINESS";
export const FETCH_SUCCESS_BUSINESS = "FETCH_SUCCESS_BUSINESS";
export const FETCH_ERROR_BUSINESS = "FETCH_ERROR_BUSINESS";
export const SET_APPOINTMENTS_BUSINESS = "SET_APPOINTMENTS_BUSINESS";

//CURRICULUM
export const FETCH_ERROR_CURRICULUM = "FETCH_ERROR_CURRICULUM";
export const FETCH_START_CURRICULUM = "FETCH_START_CURRICULUM";
export const FETCH_SUCCESS_CURRICULUM = "FETCH_SUCCESS_CURRICULUM";
export const FETCH_ACTION_CURRICULUM = "FETCH_ACTION_CURRICULUM";
export const SET_CURRICULUM = "SET_CURRICULUM";

//CURRICULUM
export const FETCH_ERROR_SCHEDULE = "FETCH_ERROR_SCHEDULE";
export const FETCH_START_SCHEDULE = "FETCH_START_SCHEDULE";
export const FETCH_SUCCESS_SCHEDULE = "FETCH_SUCCESS_SCHEDULE";
export const SET_ACTION_SCHEDULE = "SET_ACTION_SCHEDULE";
export const RESET_ACTION_SCHEDULES = "RESET_ACTION_SCHEDULES";
export const SET_SCHEDULES = "SET_SCHEDULES";
export const SET_SCHEDULE_DEFAULT = "SET_SCHEDULE_DEFAULT";


//PLANS
export const FETCH_ERROR_PLANS = "FETCH_ERROR_PLANS";
export const FETCH_START_PLANS = "FETCH_START_PLANS";
export const FETCH_SUCCESS_PLANS = "FETCH_SUCCESS_PLANS";
export const SET_ACTION_PLANS = "SET_ACTION_PLANS";
export const SET_PLANS = "SET_PLANS";

//VARIABLES
export const FETCH_START_VARIABLES = "FETCH_START_VARIABLES";
export const FETCH_SUCCESS_VARIABLES = "FETCH_SUCCESS_VARIABLES";
export const FETCH_ERROR_VARIABLES = "FETCH_ERROR_VARIABLES";
export const SET_VARIABLES = "SET_VARIABLES";
export const SET_VARIABLES_ACTION = "SET_VARIABLES_ACTION";
export const SET_VARIABLES_GLOBAL = "SET_VARIABLES_GLOBAL";
export const FETCH_START_VARIABLES_MODAL = "FETCH_START_PRODUCT_MODAL";
export const FETCH_SUCCESS_VARIABLES_MODAL = "FETCH_SUCCESS_PRODUCT_MODAL";
export const FETCH_ERROR_VARIABLES_MODAL = "FETCH_ERROR_PRODUCT_MODAL";
export const SET_VARIABLES_ACTION_MODAL = "SET_PRODUCTS_ACTION_MODAL";

//VACATIONS
export const FETCH_ERROR_VACATIONS = "FETCH_ERROR_VACATIONS";
export const FETCH_START_VACATIONS = "FETCH_START_VACATIONS";
export const RESET_ACTION_VACATIONS = "RESET_ACTION_VACATIONS";
export const FETCH_SUCCESS_VACATIONS = "FETCH_SUCCESS_VACATIONS";
export const SET_ACTION_VACATIONS = "SET_ACTION_VACATIONS";
export const SET_VACATIONS = "SET_VACATIONS";

//APPOINTMENTS
export const FETCH_ERROR_APPOINTMENTS = "FETCH_ERROR_APPOINTMENTS";
export const FETCH_START_APPOINTMENTS = "FETCH_START_APPOINTMENTS";
export const FETCH_SUCCESS_APPOINTMENTS = "FETCH_SUCCESS_APPOINTMENTS";
export const SET_ACTION_APPOINTMENTS = "SET_ACTION_APPOINTMENTS";
export const SET_APPOINTMENTS = "SET_APPOINTMENTS";

//USER
export const FETCH_START_ACCOUNT_USER = "FETCH_START_ACCOUNT_USER";
export const FETCH_SUCCESS_ACCOUNT_USER = "FETCH_SUCCESS_ACCOUNT_USER";
export const FETCH_ERROR_ACCOUNT_USER = "FETCH_ERROR_ACCOUNT_USER";
export const SET_ACTION_ACCOUNT_USER = "SET_ACTION_ACCOUNT_USER";
export const SET_ACCOUNT_USER = "SET_ACCOUNT_USER";

//DASHBOARD
export const FETCH_START_DASHBOARD = "FETCH_START_DASHBOARD";
export const FETCH_SUCCESS_DASHBOARD = "FETCH_SUCCESS_DASHBOARD";
export const FETCH_ERROR_DASHBOARD = "FETCH_ERROR_DASHBOARD";
export const SET_ACTION_DASHBOARD = "SET_ACTION_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";

//PRESCRIPTIONS
export const FETCH_ERROR_PRESCRIPTIONS = "FETCH_ERROR_PRESCRIPTIONS";
export const FETCH_START_PRESCRIPTIONS = "FETCH_START_PRESCRIPTIONS";
export const FETCH_SUCCESS_PRESCRIPTIONS = "FETCH_SUCCESS_PRESCRIPTIONS";
export const SET_ACTION_PRESCRIPTIONS = "SET_ACTION_PRESCRIPTIONS";
export const SET_PRESCRIPTIONS = "SET_PRESCRIPTIONS";

//MEDICINES
export const FETCH_ERROR_MEDICINES = "FETCH_ERROR_MEDICINES";
export const FETCH_START_MEDICINES = "FETCH_START_MEDICINES";
export const FETCH_SUCCESS_MEDICINES = "FETCH_SUCCESS_MEDICINES";
export const SET_ACTION_MEDICINES = "SET_ACTION_MEDICINES";
export const SET_MEDICINES = "SET_MEDICINES";

//HISTORIES
export const FETCH_ERROR_HISTORIES = "FETCH_ERROR_HISTORIES";
export const FETCH_START_HISTORIES = "FETCH_START_HISTORIES";
export const FETCH_SUCCESS_HISTORIES = "FETCH_SUCCESS_HISTORIES";
export const SET_ACTION_HISTORIES = "SET_ACTION_HISTORIES";
export const SET_HISTORIES = "SET_HISTORIES";

//Cobros
export const FETCH_ERROR_COBROS = "FETCH_ERROR_COBROS";
export const FETCH_START_COBROS = "FETCH_START_COBROS";
export const FETCH_SUCCESS_COBROS = "FETCH_SUCCESS_COBROS";
export const SET_ACTION_COBROS = "SET_ACTION_COBROS";
export const SET_COBROS = "SET_COBROS";

//Refers
export const FETCH_ERROR_REFERS = "FETCH_ERROR_REFERS";
export const FETCH_START_REFERS = "FETCH_START_REFERS";
export const FETCH_SUCCESS_REFERS = "FETCH_SUCCESS_REFERS";
export const SET_ACTION_REFERS = "SET_ACTION_REFERS";
export const SET_REFERS = "SET_REFERS";
export const SET_REFERS_REPORT = "SET_REFERS_REPORT";
export const SET_REFERS_INVITATION = "SET_REFERS_INVITATION";

//PaymentRefers
export const FETCH_ERROR_PAYMENT_REFERS = "FETCH_ERROR_PAYMENT_REFERS";
export const FETCH_START_PAYMENT_REFERS = "FETCH_START_PAYMENT_REFERS";
export const FETCH_SUCCESS_PAYMENT_REFERS = "FETCH_SUCCESS_PAYMENT_REFERS";
export const SET_ACTION_PAYMENT_REFERS = "SET_ACTION_PAYMENT_REFERS";
export const SET_PAYMENT_REFERS = "SET_PAYMENT_REFERS";

//Charges
export const FETCH_ERROR_CHARGES = "FETCH_ERROR_CHARGES";
export const FETCH_START_CHARGES = "FETCH_START_CHARGES";
export const FETCH_SUCCESS_CHARGES = "FETCH_SUCCESS_CHARGES";
export const SET_ACTION_CHARGES = "SET_ACTION_CHARGES";
export const SET_CHARGES = "SET_CHARGES";
export const SET_PRE_CHARGES = "SET_PRE_CHARGES";
export const SET_RESET_CHARGES = "SET_RESET_CHARGES";


//ChargesDoctor
export const FETCH_ERROR_CHARGES_DOCTOR = "FETCH_ERROR_CHARGES_DOCTOR";
export const FETCH_START_CHARGES_DOCTOR = "FETCH_START_CHARGES_DOCTOR";
export const FETCH_SUCCESS_CHARGES_DOCTOR = "FETCH_SUCCESS_CHARGES_DOCTOR";
export const SET_ACTION_CHARGES_DOCTOR = "SET_ACTION_CHARGES_DOCTOR";
export const SET_CHARGES_DOCTOR = "SET_CHARGES_DOCTOR";

//Order
export const FETCH_START_ORDER = "FETCH_START_ORDER";
export const FETCH_SUCCESS_ORDER = "FETCH_SUCCESS_ORDER";
export const FETCH_ERROR_ORDER = "FETCH_ERROR_ORDER";
export const SET_ACTION_ORDER = "SET_ACTION_ORDER";
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";

//INVOICES
export const FETCH_ERROR_INVOICES = "FETCH_ERROR_INVOICES";
export const FETCH_START_INVOICES = "FETCH_START_INVOICES";
export const FETCH_SUCCESS_INVOICES = "FETCH_SUCCESS_INVOICES";
export const SET_INVOICES = "SET_INVOICES";
export const SET_ACTION_INVOICES = "SET_ACTION_INVOICES";
export const SET_ACTION_INVOICES_MODAL = "SET_ACTION_INVOICES_MODAL";
export const FETCH_ERROR_INVOICES_MODAL = "FETCH_ERROR_INVOICES_MODAL";
export const FETCH_START_INVOICES_MODAL = "FETCH_START_INVOICES_MODAL";
export const FETCH_SUCCESS_INVOICES_MODAL = "FETCH_SUCCESS_INVOICES_MODAL";

//Reports Users
export const FETCH_START_REPORT_USERS = "FETCH_START_REPORT_USERS";
export const FETCH_SUCCESS_REPORT_USERS = "FETCH_SUCCESS_REPORT_USERS";
export const FETCH_ERROR_REPORT_USERS = "FETCH_ERROR_REPORT_USERS";
export const SET_REPORT_USERS = "SET_REPORT_USERS";
export const SET_ACTION_REPORT_USERS = "SET_ACTION_REPORT_USERS";

export const FETCH_START_REPORT_COBROS = "FETCH_START_REPORT_COBROS";
export const FETCH_SUCCESS_REPORT_COBROS = "FETCH_SUCCESS_REPORT_COBROS";
export const FETCH_ERROR_REPORT_COBROS = "FETCH_ERROR_REPORT_COBROS";
export const SET_REPORT_COBROS = "SET_REPORT_COBROS";
export const SET_ACTION_REPORT_COBROS = "SET_ACTION_REPORT_COBROS";

export const FETCH_START_REPORT_PAGOS = "FETCH_START_REPORT_PAGOS";
export const FETCH_SUCCESS_REPORT_PAGOS = "FETCH_SUCCESS_REPORT_PAGOS";
export const FETCH_ERROR_REPORT_PAGOS = "FETCH_ERROR_REPORT_PAGOS";
export const SET_REPORT_PAGOS = "SET_REPORT_PAGOS";
export const SET_ACTION_REPORT_PAGOS = "SET_ACTION_REPORT_PAGOS";

export const FETCH_START_REPORT_ASESORS = "FETCH_START_REPORT_ASESORS";
export const FETCH_SUCCESS_REPORT_ASESORS = "FETCH_SUCCESS_REPORT_ASESORS";
export const FETCH_ERROR_REPORT_ASESORS = "FETCH_ERROR_REPORT_ASESORS";
export const SET_REPORT_ASESORS = "SET_REPORT_ASESORS";
export const SET_ACTION_REPORT_ASESORS = "SET_ACTION_REPORT_ASESORS";

export const FETCH_START_REPORT_RESERVAS = "FETCH_START_REPORT_RESERVAS";
export const FETCH_SUCCESS_REPORT_RESERVAS = "FETCH_SUCCESS_REPORT_RESERVAS";
export const FETCH_ERROR_REPORT_RESERVAS = "FETCH_ERROR_REPORT_RESERVAS";
export const SET_REPORT_RESERVAS = "SET_REPORT_RESERVAS";
export const SET_ACTION_REPORT_RESERVAS = "SET_ACTION_REPORT_RESERVAS";

export const FETCH_START_REPORT_PAGOS_LINEA = "FETCH_START_REPORT_PAGOS_LINEA";
export const FETCH_SUCCESS_REPORT_PAGOS_LINEA = "FETCH_SUCCESS_REPORT_PAGOS_LINEA";
export const FETCH_ERROR_REPORT_PAGOS_LINEA = "FETCH_ERROR_REPORT_PAGOS_LINEA";
export const SET_REPORT_PAGOS_LINEA = "SET_REPORT_PAGOS_LINEA";
export const SET_ACTION_REPORT_PAGOS_LINEA = "SET_ACTION_REPORT_PAGOS_LINEA";

export const FETCH_START_REPORT_COLABORADORES = "FETCH_START_REPORT_COLABORADORES";
export const FETCH_SUCCESS_REPORT_COLABORADORES = "FETCH_SUCCESS_REPORT_COLABORADORES";
export const FETCH_ERROR_REPORT_COLABORADORES = "FETCH_ERROR_REPORT_COLABORADORES";
export const SET_REPORT_COLABORADORES = "SET_REPORT_COLABORADORES";
export const SET_ACTION_REPORT_COLABORADORES = "SET_ACTION_REPORT_COLABORADORES";

//Reports Attempts
export const FETCH_START_REPORT_ATTEMPTS = "FETCH_START_REPORT_ATTEMPTS";
export const FETCH_SUCCESS_REPORT_ATTEMPTS = "FETCH_SUCCESS_REPORT_ATTEMPTS";
export const FETCH_ERROR_REPORT_ATTEMPTS = "FETCH_ERROR_REPORT_ATTEMPTS";
export const SET_REPORT_ATTEMPTS = "SET_REPORT_ATTEMPTS";
export const SET_ACTION_REPORT_ATTEMPTS = "SET_ACTION_REPORT_ATTEMPTS";