import {INIT_URL, FETCH_START_ACCOUNT_USER, FETCH_SUCCESS_ACCOUNT_USER, FETCH_ERROR_ACCOUNT_USER, SET_ACTION_ACCOUNT_USER, SET_ACCOUNT_USER} from "constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	account_user: null,
	error_account: "",
	loading_account: false,
	message_account: "",
	action_account: ""
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case FETCH_START_ACCOUNT_USER: {
		return {...state, error_account: "", message_account: "", loading_account: true};
	}

	case FETCH_SUCCESS_ACCOUNT_USER: {
		return {...state, error_account: "", message_account: "", loading_account: false};
	}

	case FETCH_ERROR_ACCOUNT_USER: {
		return {...state, loading_account: false, error_account: action.payload, message_account: ""};
	}

	case SET_ACTION_ACCOUNT_USER: {
		return {
			...state,
			action_account: action.payload,
		};
	}

	case SET_ACCOUNT_USER: {
		return {
			...state,
			account_user: action.payload,
		};
	}

	default:
		return state;
	}
};
