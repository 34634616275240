import {
	FETCH_ERROR_VARIABLES,
	FETCH_START_VARIABLES,
	FETCH_SUCCESS_VARIABLES,
	INIT_URL,
	SET_VARIABLES,
	SET_VARIABLES_ACTION,
	SET_VARIABLES_GLOBAL,
	FETCH_START_VARIABLES_MODAL,
	FETCH_SUCCESS_VARIABLES_MODAL,
	SET_VARIABLES_ACTION_MODAL,
	FETCH_ERROR_VARIABLES_MODAL
} from "constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
  
export const getVariables = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_VARIABLES});
      
		axios.get("variables?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_VARIABLES});
				dispatch({type: SET_VARIABLES, payload: data.variables});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_VARIABLES, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_VARIABLES, payload: error});
		});
	};
};

export const getVariablesGlobal = () => {
	return async (dispatch) => {
		dispatch({type: FETCH_START_VARIABLES});
		await axios.get("variablesGlobal").then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS_VARIABLES});
				dispatch({type: SET_VARIABLES_GLOBAL, payload: data.variables});
				//localStorage.setItem("general", JSON.stringify(data.general));
			} else {
				dispatch({type: FETCH_ERROR_VARIABLES, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_VARIABLES, payload: error});
		});
	};
};

export const setAction = (action) => {
	return (dispatch) => {
		dispatch({type: SET_VARIABLES_ACTION, payload: action});
	};
};

export const resetActions = () => {
	return (dispatch) => {
		dispatch({type: SET_VARIABLES_ACTION, payload: null});
	};
};

export const updateVariable = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_VARIABLES_MODAL});
		axios.put("variables", datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_VARIABLES_MODAL});
				dispatch({type: SET_VARIABLES_ACTION_MODAL, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_VARIABLES_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_VARIABLES_MODAL, payload: error});
		});
	};
};

