import { SET_MEDICINES} from "constants/ActionTypes";
import {FETCH_START_MEDICINES, FETCH_SUCCESS_MEDICINES, SET_ACTION_MEDICINES, FETCH_ERROR_MEDICINES} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	medicines: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_MEDICINES: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_MEDICINES: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_MEDICINES: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_MEDICINES: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_MEDICINES: {
		return {...state, medicines: action.payload};
	}
	default:
		return state;
	}
};
