import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, USER_MENU_SET, USER_PERMISSIONS_SET, USER_ACTIONS_SET, RESET_AUTH} from "../../constants/ActionTypes";

const INIT_STATE = {
	token: JSON.parse(localStorage.getItem("token")),
	initURL: "",
	authUser: JSON.parse(localStorage.getItem("user")),
	menu: null,
	permissions: null,
	can: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case SIGNOUT_USER_SUCCESS: {
		return {
			...state,
			token: null,
			authUser: null,
			initURL: "",
			menu: null
		};
	}

	case USER_DATA: {
		return {
			...state,
			authUser: action.payload,
		};
	}

	case USER_TOKEN_SET: {
		return {
			...state,
			token: action.payload,
		};
	}

	case USER_MENU_SET: {
		return {
			...state,
			menu: action.payload,
		};
	}

	case USER_PERMISSIONS_SET: {
		return {
			...state,
			permissions: action.payload,
		};
	}

	case USER_ACTIONS_SET: {
		return {
			...state,
			can: action.payload,
		};
	}

	case RESET_AUTH: {
		return {
			...state,
			token: null,
			initURL: "/",
			authUser: null,
			menu: null,
			permissions: null,
			can: null
		};
	}

	default:
		return state;
	}
};
