import { SET_HISTORIES} from "constants/ActionTypes";
import {FETCH_START_HISTORIES, FETCH_SUCCESS_HISTORIES, SET_ACTION_HISTORIES, FETCH_ERROR_HISTORIES} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	histories: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_HISTORIES: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_HISTORIES: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_HISTORIES: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_HISTORIES: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_HISTORIES: {
		return {...state, histories: action.payload};
	}
	default:
		return state;
	}
};
