/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, {useEffect} from "react";
import {Affix, Button, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn } from "../appRedux/actions/Auth";
import {resetActionCommon } from "../appRedux/actions/Common";
import IntlMessages from "util/IntlMessages";
import { TeamOutlined } from "@ant-design/icons";

const SignIn = (props) => {
  
	const dispatch = useDispatch();
	const token = useSelector(({auth}) => auth.token);
	const user = useSelector(({auth}) => auth.authUser);
	const error = useSelector(({common}) => common.error);
	const loading = useSelector(({common}) => common.loading);
  
  

	const onFinishFailed = () => {
		//console.log('Failed:', errorInfo);
	};

	const onFinish = values => {
		//console.log("finish",values)
    
		dispatch(userSignIn(values));
	};

	useEffect(() => {
		//dispatch(getVariablesGlobal())
		if (token !== null) {
			if(user.af){
				if(!user.rl.includes(`${process.env.REACT_APP_ROL_NEGOCIO}`) && !user.rl.includes(`${process.env.REACT_APP_ROL_DOCTOR}`)){
					props.history.push("/profile");
				}else{
					props.history.push("/negocio");
				}
      
			}else{ 
				props.history.push("/dashboard");
			}
		}

    
	}, [token, props.history]);

	useEffect(() => {
		if(error){
			message.error(error);
		}
	}, [error]);

  
	useEffect(() => {
		return () => {
			resetActionCommon();
		}; 
	}, []);

	return (
		<>
			<Affix offsetTop={20} >
				<Button href={`${process.env.REACT_APP_PACIENT_FRONT}`} style={{float: "right", marginRight: "20px", backgroundColor: "cyan"}} icon={<TeamOutlined />}>
          Área de Pacientes
				</Button>
			</Affix>
			<div className="gx-app-login-wrap">
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-logo-content">
							<div className="gx-app-logo-content-bg">
								<img src={require("assets/images/doctors.png")} alt='Neature'/>
							</div>
							<div className="gx-app-logo-wid">
								{/* <h1><IntlMessages id="app.userAuth.signIn"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
							</div>
							<div className="gx-app-logo">
								<img alt="example" src={require("assets/images/logo.png")}/>
							</div>
						</div>
						<div className="gx-app-login-content">
							<Form
								initialValues={{ remember: true }}
								name="basic"
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								className="gx-signin-form gx-form-row0">

								<Form.Item
									initialValue=""
									rules={[{ required: true, message: "Ingrese su correo o CI/DNI" }]} name="email">
									<Input placeholder="Correo electrónico o CI/DNI"/>
								</Form.Item>
								<Form.Item
									initialValue=""
									rules= {[{required: true, message: "Ingrese su contraseña"}]}  name="password">
									<Input type="password" placeholder="Contraseña"/>
								</Form.Item>
								<Form.Item valuePropName="checked">
									{/* <Checkbox><IntlMessages id="app.userAuth.remember"/></Checkbox> */}
									<Link className="gx-login-form-forgot" to="/forgot"><IntlMessages id="app.userAuth.forgotMe"/></Link>
								</Form.Item>
								<Form.Item>
									<Button type="primary" className="gx-mb-0" loading={loading} htmlType="submit">
										<IntlMessages id="app.userAuth.signIn"/>
									</Button>
									<span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
										id="app.userAuth.signUp"/></Link>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignIn;
