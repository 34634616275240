import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Col, Popover, Row} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import { useHistory } from "react-router-dom";

const UserProfileMobileMobile = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem("user"));
  
	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => history.push("/profile")}>Mi Perfil </li>
			<li onClick={() => dispatch(userSignOut())}>Cerrar sesión
			</li>
		</ul>
	);

	return (
		<>
			<div>					
				<Popover placement="bottomRight" content={userMenuOptions} trigger="click" >        
					<Avatar src={user ? `${process.env.REACT_APP_BACK}`+user.photo : "https://api.novacita.com/docs/users/profile/user.png"}
						className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
				</Popover>      
			</div>
		</>
	);
};

export default UserProfileMobileMobile;
