import {INIT_URL, CITIES_SET } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	cities: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case CITIES_SET: {
		return {
			...state,
			cities: action.payload,
		};
	}

	default:
		return state;
	}
};
